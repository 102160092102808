import { render, staticRenderFns } from "./Stored.vue?vue&type=template&id=481d0f8a&scoped=true&"
import script from "./Stored.vue?vue&type=script&lang=js&"
export * from "./Stored.vue?vue&type=script&lang=js&"
import style0 from "./Stored.vue?vue&type=style&index=0&id=481d0f8a&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.10.0@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "481d0f8a",
  null
  
)

export default component.exports