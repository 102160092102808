<template>
    <div class="zt-page-content" style="overflow-x:auto;">
        <div style="height:100%;min-width:1350px;">
            <div class="header">
                <div class="search-bar flex flex-align-center">
                    <div style="width:100px;font-size:18px;color:rgba(41,41,41,1);"><b>设计作品库</b></div>
                    <el-input v-model="query.k" placeholder="作品名" style="max-width: 500px;margin-left: 50px;">
                        <el-button @click="search()" slot="append" icon="el-icon-search"></el-button>
                    </el-input>
                </div>
                <div class="tab_menu flex">
                    <div v-for="(item,index) in tabList" :key="index" @click="tab(index)" class="menu_item" :class="{'cur':tabIndex == index}">
                        {{item.name}}
                    </div>
                </div>
            </div>
            <div class="zt-block flex flex-align-center" style="padding:20px">
                <div class="select-wrap flex flex-align-center">
                    <label>风格</label>
                    <el-select v-model="query.style_no" @change="handleChange" :popper-append-to-body="false" placeholder="全部" size="small">
                        <el-option v-for="item in config.styles" :key="item.id"
                        :label="item.name" :value="item.id">
                        </el-option>
                    </el-select>
                </div>
                <div v-if="tabIndex==0" class="select-wrap flex flex-align-center">
                    <label>类目</label>
                    <el-cascader :options="config.cats" @change="handleChange" :show-all-levels="false" :props="{value:'id',label:'name',emitPath:false}"
                        v-model="query.cat_id" placeholder="全部" size="small">
                    </el-cascader>
                </div>
                <div class="select-wrap flex flex-align-center">
                    <label>出售渠道</label>
                    <el-select v-model="query.price_type" @change="handleChange" :popper-append-to-body="false" placeholder="全部" size="small">
                        <el-option v-for="item in config.sale_channel" :key="item.id"
                        :label="item.name" :value="item.id">
                        </el-option>
                    </el-select>
                </div>
                <div class="select-wrap">
                    <el-checkbox @change="handleChange()" v-model="query.avail_only">只显示未出售</el-checkbox>
                </div>
            </div>
            <div class="zt-block" style="min-width:1250px;height:calc(100% - 240px)">
                <Single v-if="tabIndex==0 && hackReset" :query="query"></Single>
                <Series v-if="tabIndex==1 && hackReset" :query="query"></Series>
            </div>
        </div>
    </div>
</template>

<script>
    import { getCats, getStyles } from '@/service/item';
    import { fetchTree } from '@/util';
    import Single from './single/Single'; // 单品作品
    import Series from './series/Series'; // 套系作品
    export default {
        components: {
            Single, Series,
        },
        data() {
            return {
                config:{
                    styles: null, // 风格 
                    cats: null, // 类目
                    sale_channel:[
                        { id: "", name: "全部" },
                        { id: 0, name: "版权购买" },
                        // { id: 1, name: "分成合作" },
                        // { id: 3, name: "需求定制" },
                        { id: 4, name: "暂不销售" }
                    ]
                },
                query:{
                    k: "",
                    style_no: "",
                    cat_id: 0,
                    price_type: "",
                    avail_only: false,
                },
                tabList:[
                    {name:"单品作品"},
                    {name:"套系作品"},
                ],
                tabIndex: 0,
                hackReset:true,//刷新子组件
                type: 0, // 作品类型 0单品作品，1套系作品
            }
        },
        async created() {
            this.type = this.$route.query.type;
            if (this.$route.query.type==1) {
                this.tabIndex = 1;
            }
            this.catList = await getCats();
            this.config.cats = fetchTree(this.catList, null, { name: { dest: 'name' }, id: { dest: 'id' }, parent: 'parentId' });
            this.config.cats = [{ id:0, name:"全部" }].concat(this.config.cats);
            console.log(this.config.cats);
            this.config.styles = [{ id:"", name:"全部" }].concat(await getStyles());
        },
        mounted() {},
        methods: {
            gotolink(path){
                this.$router.push(path);
            },
            // 选项卡
            tab(index) {
                this.tabIndex = index;
                if (index == 1) {
                   this.$router.push({ query:{ type: 1} });
                } else {
                    this.$router.push({ query:{ type: 0} });
                }
                console.log(index);
            },
            search() {
                this.hackReset = false;
                this.$nextTick(() => {
                    this.hackReset = true;
                })
            },
            handleChange() {
                console.log("this.query",this.query);
                this.hackReset = false;
                this.$nextTick(() => {
                    this.hackReset = true;
                })
            },
            
        }
    }
</script>
<style scoped lang="less">
.header {
    width: 100%;
    padding: 0 60px;
    background: #FFFFFF;
    box-shadow: 0px 2px 4px 0px #D1D9E2;
    .search-bar {
        padding: 20px 0 10px;
    }
    .tab_menu {
        .menu_item {
            height: 38px;
            line-height: 38px;
            margin-right: 40px;
            cursor: pointer;
            font-size: 16px;
            font-weight: 500;
            color: #4D4D4D;
            &.cur {
                color: #5074EE;
                border-bottom: 2px solid #5074EE;
            }
        }
    }
}
.select-wrap {
    margin-right: 30px;
    label {
        font-size: 14px;
        color: #292929;
        margin-right: 15px;
    }
}
/*----选项卡----*/
.content-header {
    width: 1250px;
    margin: 0 auto;
    padding: 17px 0 7px 0;
}
</style>